import React, { createContext, ReactNode, useEffect, useReducer, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { firebaseAuth as auth, firestore as db } from "../firebase";
import UserDataService from "../services/user.service";
// @types
import { ActionMap, AuthState, AuthUser, FirebaseContextType } from "../@types/auth";
//
import { sendPasswordResetEmail, signInWithEmailAndPassword, updatePassword } from "firebase/auth";
import { to } from "../utils/promises";
import { UserDataID, UserPublicData } from "../@types/user";
import { User } from "../@types/user";

// ----------------------------------------------------------------------

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

enum Types {
  Initial = "INITIALISE",
}

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
};

type FirebaseActions = ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === "INITIALISE") {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [profile, setProfile] = useState<UserDataID | undefined>();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.isAuthenticated) {
      UserDataService.databaseAndFirestorePresence();
    }
  }, [state]);

  useEffect(
    () =>
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const docRef = doc(db, "users", user.uid);
          const docPublicRef = doc(db, "users", user.uid, "public", "data");

          const [error, docSnap] = await to(getDoc(docRef));
          const [error2, docPublicSnap] = await to(getDoc(docPublicRef));

          if (error || error2) {
            console.log(error);
            console.log(error2);
            dispatch({
              type: Types.Initial,
              payload: { isAuthenticated: true, user },
            });
          } else {
            if (docSnap.exists()) {
              setProfile({ ...(docSnap.data() as User), ...(docPublicSnap.data() as UserPublicData) });
            }
            dispatch({
              type: Types.Initial,
              payload: { isAuthenticated: true, user },
            });
          }
        } else
          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: false, user: null },
          });
      }),
    [dispatch],
  );

  const login = async (email: string, password: string) => {
    await signInWithEmailAndPassword(auth, email, password);
  };

  const logout = async () => {
    await auth.signOut();
  };

  const resetPassword = async (email: string) => {
    await sendPasswordResetEmail(auth, email);
  };

  const changePassword = async (newPassword: string) => {
    if (auth.currentUser) await updatePassword(auth.currentUser, newPassword);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "firebase",
        user: {
          id: profile?.id,
          name: profile?.name,
          email: profile?.email,
          type: profile?.type,
          lastChanged: profile?.lastChanged,
          online: profile?.online,
          statusUpdates: profile?.statusUpdates,
          accounts: profile?.accounts,
        },
        login,
        logout,
        resetPassword,
        changePassword,
        updateProfile: () => {},
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
