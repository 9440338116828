import React from "react";
import { Navigate } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// routes
import { PATH_404 } from "../routes/paths";

// ----------------------------------------------------------------------

type AccessBasedGuardProp = {
  type?: string[];
  children: React.ReactNode;
};

export default function AccessGuard({ type, children }: AccessBasedGuardProp) {
  const { user } = useAuth();
  const currentType = user?.type ? user.type : [];

  if (typeof type !== "undefined" && !type.includes(currentType)) {
    return <Navigate to={PATH_404.notFound} />;
  }

  return <>{children}</>;
}
