import moment from "moment-business-days";
import { firestore, firebaseDatabase } from "../firebase";
import { getAuth } from "firebase/auth";
import { ref, onDisconnect, set, onValue, push } from "firebase/database";
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  collectionGroup,
  query,
  runTransaction,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import useAuth from "../hooks/useAuth";

// ----------------------------------------------------------------------

class UserDataService {
  getAll() {
    return collection(firestore, "users");
  }

  getDoc(id: string) {
    return doc(firestore, "users", id);
  }

  getSubcollection(id: string) {
    return doc(firestore, "users", id, "public", "data");
  }

  getSubcollections() {
    return query(collectionGroup(firestore, "public"));
  }

  databaseAndFirestorePresence() {
    const auth = getAuth();
    const uid = auth?.currentUser?.uid as string;

    const userStatusDatabaseRef = ref(firebaseDatabase, "/status/" + uid + "/connections");
    const userStatusLastChangedRef = ref(firebaseDatabase, "/status/" + uid + "/lastChanged");
    const userConnectionStatus = ref(firebaseDatabase, ".info/connected");

    onValue(userConnectionStatus, async (snapshot) => {
      if (snapshot.val() === true) {
        const con = push(userStatusDatabaseRef);
        set(con, true);

        set(userStatusLastChangedRef, moment().valueOf());

        onDisconnect(con).remove();
        onDisconnect(userStatusLastChangedRef).set(moment().valueOf());
        return;
      }
    });
  }

  getCurrentUserName() {
    const { user } = useAuth();
    if (user) return user.name;
  }

  async create(value: any, subCollectionValue: any) {
    const invitesRef = collection(firestore, "invites");
    await addDoc(invitesRef, { ...value, ...subCollectionValue });
  }

  async update(id: string, value: any) {
    const updateRef = doc(firestore, "users", id, "public", "data");
    await updateDoc(updateRef, value);
  }

  async updateTransaction(
    id: string,
    value: any,
    subCollectionValue: any,
    originalAccounts: any,
    originalUserData: any,
  ) {
    await runTransaction(firestore, async (transaction) => {
      const updateRef = doc(firestore, "users", id);
      const subcollectionRef = doc(firestore, "users", id, "public", "data");
      transaction.update(updateRef, value);
      transaction.set(subcollectionRef, subCollectionValue);

      const updatedAccounts = value?.accounts;

      if (
        subCollectionValue?.name !== originalUserData?.name ||
        subCollectionValue?.statusUpdates !== originalUserData?.statusUpdates ||
        value?.type !== originalUserData?.type
      ) {
        for (let i = 0; i < updatedAccounts.length; i++) {
          const updateRef = doc(firestore, "accounts", updatedAccounts[i]?.id, "private", "users");
          transaction.update(updateRef, {
            users: arrayRemove({
              id: id,
              name: originalUserData?.name,
              type: originalUserData?.type,
              email: originalUserData?.email,
              statusUpdates: originalUserData?.statusUpdates,
            }),
          });
          transaction.update(updateRef, {
            users: arrayUnion({
              id: id,
              name: subCollectionValue?.name,
              type: value?.type,
              email: subCollectionValue?.email,
              statusUpdates: subCollectionValue?.statusUpdates,
            }),
          });
        }
      }

      // Added an account
      const newAccounts = updatedAccounts.filter(
        (updatedAccount: any) =>
          !originalAccounts.some((originalAccount: any) => originalAccount.id === updatedAccount.id),
      );
      for (let i = 0; i < newAccounts.length; i++) {
        const updateRef = doc(firestore, "accounts", newAccounts[i]?.id, "private", "users");
        transaction.update(updateRef, {
          users: arrayUnion({
            id: id,
            name: subCollectionValue?.name,
            type: value?.type,
            email: subCollectionValue?.email,
            statusUpdates: subCollectionValue?.statusUpdates,
          }),
        });
      }

      // Removed an account
      const removedAccounts = originalAccounts.filter(
        (originalAccount: any) =>
          !updatedAccounts.some((updatedAccount: any) => updatedAccount.id === originalAccount.id),
      );
      for (let i = 0; i < removedAccounts.length; i++) {
        const deleteRef = doc(firestore, "accounts", removedAccounts[i]?.id, "private", "users");
        transaction.update(deleteRef, {
          users: arrayRemove({
            id: id,
            name: subCollectionValue?.name,
            type: value?.type,
            email: subCollectionValue?.email,
            statusUpdates: subCollectionValue?.statusUpdates,
          }),
        });
      }
    });
  }

  async deleteTransaction(id: string, accounts: any, userDocData: any) {
    await runTransaction(firestore, async (transaction) => {
      const deleteRef = doc(firestore, "users", id);
      const deleteSubcollectionRef = doc(firestore, "users", id, "public", "data");
      transaction.delete(deleteSubcollectionRef);
      transaction.delete(deleteRef);

      for (let i = 0; i < accounts?.length; i++) {
        const accountsRef = doc(firestore, "accounts", accounts[i]?.id, "private", "users");
        transaction.update(accountsRef, {
          users: arrayRemove({
            id: id,
            name: userDocData?.name,
            type: userDocData?.type,
            email: userDocData?.email,
            statusUpdates: userDocData?.statusUpdates,
          }),
        });
      }
    });
  }
}

export default new UserDataService();
