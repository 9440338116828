import React, { useEffect } from "react";
// @mui
import { Box, Stack, Card, useTheme, Typography, Link } from "@mui/material";
// components
import Page from "../../components/Page";
import LoginForm from "./LoginForm";
// hooks
import useDocumentHeight from "../../hooks/useDocumentHeight";

// ----------------------------------------------------------------------

export default function LoginPage() {
  const { palette } = useTheme();
  const documentHeight = useDocumentHeight();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  return (
    <Page
      title="Login"
      sx={{
        minHeight: "100vh",
        backgroundColor: palette.grey[100],
      }}
    >
      <Box
        sx={{
          height: documentHeight,
          minHeight: {
            xs: "550px",
            sm: "600px",
            md: "700px",
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            px: {
              xs: 3,
              sm: 5,
              md: 6,
            },
            py: {
              xs: 4,
              sm: 5,
              md: 6,
            },
            width: {
              xs: "350px",
              sm: "400px",
              md: "450px",
            },
            zIndex: 2,
          }}
        >
          <Stack alignItems="center" sx={{ mb: 3, p: 2 }}>
            <img width="70%" src="/logo-blackBlue.svg"></img>
          </Stack>
          <LoginForm />
        </Card>

        <Link
          target="_blank"
          href={"https://www.ifrog.com/"}
          sx={{ position: "absolute", bottom: "20px", color: palette.grey[600], zIndex: 1 }}
          style={{ textDecoration: "none" }}
        >
          <Typography variant="subtitle2">© 2024 iFrog Marketing Solutions</Typography>
        </Link>
      </Box>
    </Page>
  );
}
