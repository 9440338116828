import { initializeApp } from "firebase/app";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { FIREBASE_API } from "./config";

const app = initializeApp(FIREBASE_API);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY as string),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const database = getDatabase(app);

if (window.location.hostname === "localhost") {
  connectFirestoreEmulator(db, "localhost", 8084);
  connectAuthEmulator(auth, "http://localhost:9094/");
  connectStorageEmulator(storage, "localhost", 9199);
  connectDatabaseEmulator(database, "127.0.0.1", 9004);
}

export const firestore = db;
export const firebaseAuth = auth;
export const firebaseStorage = storage;
export const firebaseDatabase = database;
