import React, { useState } from "react";
import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { goOnline } from "firebase/database";
import { firebaseDatabase } from "../../firebase";
import { getAuth, setPersistence, browserSessionPersistence, browserLocalPersistence } from "firebase/auth";
// @mui
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// routes
import { PATH_AUTH } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
// components
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm() {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Email must be a valid email address").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required")
      .matches(/^(?=.*[0-9])/, "Needs at least one number")
      .matches(/^(?=.*[!@#$%^&*])/, "Needs at least one special character")
      .matches(/^(?=.*[A-Za-z])/, "Needs at least one letter"),
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      // persistent login logic
      const auth = getAuth();
      setPersistence(auth, checked ? browserLocalPersistence : browserSessionPersistence);

      try {
        await login(values?.email, values?.password);
        goOnline(firebaseDatabase);
        if (isMountedRef?.current) {
          setSubmitting(false);
        }
      } catch (error: any) {
        console.error(error);
        let errorMessage = "An error occured. Please report this to the system administrator.";
        if (error?.code === "auth/user-not-found") errorMessage = "Email and/or password not valid. Try Again";

        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: errorMessage });
        }
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleCheckBox = (e: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
    setChecked(e.target.checked);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box>
          <Stack spacing={3}>
            {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email"
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Password"
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} sx={{ mr: -0.5 }}>
                      <Iconify icon={showPassword ? "ph:eye-bold" : "ph:eye-closed-bold"} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <FormControlLabel
              control={<Checkbox {...getFieldProps("remember")} onChange={handleCheckBox} />}
              label="Remember me"
            />

            <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
              Forgot password
            </Link>
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Login
          </LoadingButton>
        </Box>
      </Form>
    </FormikProvider>
  );
}
