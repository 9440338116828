import React from "react";

// @mui
import { Box, CircularProgress } from "@mui/material";

// ----------------------------------------------------------------------

export default function LoadingScreen() {
  return (
    <Box width="100vw" height="100vh" justifyContent="center" alignItems="center" display="flex">
      <CircularProgress />
    </Box>
  );
}
