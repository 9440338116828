import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "./contexts/AuthContext";
import { LocalizationProvider } from "@mui/x-date-pickers//LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import App from "./App";
import "./styles.css";

// ----------------------------------------------------------------------

const container = document.getElementById("app");
const root = createRoot(container!);
root.render(
  <AuthProvider>
    <HelmetProvider>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <App />
        </LocalizationProvider>
      </BrowserRouter>
    </HelmetProvider>
  </AuthProvider>,
);
