/* eslint-disable max-len */
import React, { Component, ReactNode, useRef } from "react";
import { IconifyIcon } from "@iconify/react";
import { SnackbarKey, SnackbarProvider, MaterialDesignContent } from "notistack";
// @mui
import { alpha, useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
// theme
import { ColorSchema } from "../theme/palette";
//
import Iconify from "./Iconify";
import { IconButtonAnimate } from "./animate";

declare module "notistack" {
  export interface SnackbarProvider extends Component<SnackbarProviderProps> {
    render(): ReactNode;
  }
}

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function NotistackProvider({ children }: Props) {
  const notistackRef = useRef<any>(null);

  const theme = useTheme();

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    "&.notistack-MuiContent-success, &.notistack-MuiContent-error, &.notistack-MuiContent-warning, &.notistack-MuiContent-info":
      {
        width: "100%",
        fontSize: "16px",
        fontWeight: "600",
        paddingInline: 20,
        margin: theme.spacing(0.25, 0),
        boxShadow: theme.customShadows.z8,
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
      },
    [theme.breakpoints.up("md")]: {
      minWidth: 240,
    },
  }));

  return (
    <>
      <SnackbarProvider
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={3000}
        variant="success" // Set default variant
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        iconVariant={{
          info: <SnackbarIcon icon={"eva:info-fill"} color="info" />,
          success: <SnackbarIcon icon={"eva:checkmark-circle-2-fill"} color="success" />,
          warning: <SnackbarIcon icon={"eva:alert-triangle-fill"} color="warning" />,
          error: <SnackbarIcon icon={"eva:alert-circle-fill"} color="error" />,
        }}
        // With close as default
        action={(key: SnackbarKey) => (
          <IconButtonAnimate size="small" onClick={onClose(key)} sx={{ p: 0.5 }}>
            <Iconify icon={"eva:close-fill"} />
          </IconButtonAnimate>
        )}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}

// ----------------------------------------------------------------------

type SnackbarIconProps = {
  icon: IconifyIcon | string;
  color: ColorSchema;
};

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: "flex",
        borderRadius: 1.5,
        alignItems: "center",
        justifyContent: "center",
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Iconify icon={icon} width={24} height={24} />
    </Box>
  );
}
