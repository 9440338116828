function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
export const PATH_AUTH = {
  login: path(ROOTS_AUTH, "/login"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
};

const ROOTS_DASHBOARD = "/dashboard";
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  campaign: path(ROOTS_AUTH, "/campaign"),
  dashboardLink: path(ROOTS_AUTH, "/:accountId"),
};

const ROOTS_PROFILE = "/profile";
export const PATH_PROFILE = {
  settings: path(ROOTS_PROFILE, "/settings"),
};

const ROOTS_ADMIN = "/admin";
export const PATH_ADMIN = {
  users: path(ROOTS_ADMIN, "/users"),
  accounts: path(ROOTS_ADMIN, "/accounts"),
  campaigns: path(ROOTS_ADMIN, "/campaigns"),
};

const ROOTS_NOT_FOUND = "/";
export const PATH_404 = {
  notFound: path(ROOTS_NOT_FOUND, "page-not-found"),
};
