const retryLimit = 4;

export const to = async <T>(
  promise: Promise<T>,
  improved?: any,
): Promise<[null, T] | [any, T]> => {
  try {
    const data = await promise;
    return [null, data];
  } catch (err: any) {
    if (improved) Object.assign(err, improved);
    return [err, null as T];
  }
};

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const tryAgain = async <T>(
  fn: () => Promise<T>,
  functionName: string,
  improved?: any,
): Promise<[null, T] | [any, T]> => {
  let retries = 0;
  let error = null;
  while (retries <= retryLimit) {
    try {
      const data = await fn();
      return [null, data];
    } catch (err: any) {
      if (retries === retryLimit) break;
      error = err;
      const delay = /* checkErrorForRetry(err) ?? */ 2 ** retries;
      console.warn(
        `  ${functionName} encountered an error, retrying in ${delay} second(s)`,
      );
      await sleep(delay * 1000);
      retries += 1;
    }
  }
  if (improved) Object.assign(error, improved);
  return [error, null as T];
};
